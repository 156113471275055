export const categories = {
  "sci-fi-fantasy": {
    id: "sci-fi-fantasy",
    name: "Science Fiction & Fantasy",
  },
  thriller: {
    id: "thriller",
    name: "Mystery & Thriller",
  },
  literature: {
    id: "literature",
    name: "Literature & Fiction",
  },
  "non-fiction": {
    id: "non-fiction",
    name: "Non Fiction",
  },
  comedy: {
    id: "comedy",
    name: "Comedy",
  },
};

export const data = {
  categories,
  books: [
    {
      id: "dear-girls",
      categoryId: "comedy",
      title: "Dear Girls",
      author: "Ali Wong",
    },
    {
      id: "utterly",
      categoryId: "sci-fi-fantasy",
      title:
        "The Utterly Uninteresting and Unadventurous Tales of Fred, the Vampire Accountant",
      author: "Drew Hayes",
    },
    {
      id: "angry-planet",
      categoryId: "sci-fi-fantasy",
      title: "The Long Way to a Small, Angry Planet",
      author: "Becky Chambers",
    },
    {
      id: "technically-wrong",
      categoryId: "non-fiction",
      title:
        "Technically Wrong: Sexist Apps, Biased Algorithms, and Other Threats of Toxic Tech",
      author: "Sara Wachter-boettcher",
    },
    {
      id: "orconomics",
      categoryId: "sci-fi-fantasy",
      title: "Orconomics",
      author: "J. Zachary Pike",
    },
    {
      id: "dial-d-for-deadman",
      categoryId: "sci-fi-fantasy",
      title: "Dial D for Deadman",
      author: "Barry J. Hutchison",
    },
    {
      id: "talk-about-race",
      categoryId: "non-fiction",
      title: "So You Want to Talk About Race",
      author: "Ijeoma Oluo",
    },
    {
      id: "the-testaments",
      categoryId: "literature",
      title: "The Testaments",
      author: "Margaret Atwood",
    },
    {
      id: "will-save-the-galaxy",
      categoryId: "sci-fi-fantasy",
      title: "Will Save the Galaxy for Food",
      author: "Yahtzee Croshaw",
    },
    {
      id: "storm-front",
      categoryId: "sci-fi-fantasy",
      title: "Storm Front: The Dresden Files",
      author: "Jim Butcher",
    },
    {
      id: "hard-luck-hank",
      categoryId: "sci-fi-fantasy",
      title: "Hard Luck Hank: Screw the Galaxy",
      author: "Steven Campbell",
    },
    {
      id: "andrew-vernon-1",
      categoryId: "sci-fi-fantasy",
      title: "Andrea Vernon and the Corporation for UltraHuman Protection",
      author: "Alexander C Kane",
    },
    {
      id: "andrew-vernon-2",
      categoryId: "sci-fi-fantasy",
      title: "Andrea Vernon and the Superhero-Industrial Complex",
      author: "Alexander C Kane",
    },
    {
      id: "off-to-be-the-wizard",
      categoryId: "sci-fi-fantasy",
      title: "Off to Be the Wizard",
      author: "Scott Meyer",
    },
    {
      id: "master-of-formalities",
      categoryId: "sci-fi-fantasy",
      title: "Master of Formalities",
      author: "Scott Meyer",
    },
    {
      id: "the-authorities",
      categoryId: "sci-fi-fantasy",
      title: "The Authorities",
      author: "Scott Meyer",
    },
    {
      id: "expanse-1",
      categoryId: "sci-fi-fantasy",
      title: "Leviathan Wakes: The Expanse, book 1",
      author: "James S. A. Corey",
    },
    {
      id: "expanse-2",
      categoryId: "sci-fi-fantasy",
      title: "Caliban's War: The Expanse, book 2",
      author: "James S. A. Corey",
    },
    {
      id: "anansi-boys",
      categoryId: "sci-fi-fantasy",
      title: "Anansi Boys",
      author: "Neil Gaiman",
    },
    {
      id: "fuzzy-nation",
      categoryId: "sci-fi-fantasy",
      title: "Fuzzy Nation",
      author: "John Scalzi",
    },
    {
      id: "ghost-in-the-wires",
      categoryId: "non-fiction",
      title:
        "Ghost in the Wires: My Adventures as the World’s Most Wanted Hacker",
      author: "Kevin Mitnick",
    },
    {
      id: "a-brief-history",
      categoryId: "non-fiction",
      title: "A Brief History of Time",
      author: "Stephen Hawking",
    },
    {
      id: "killing-floor",
      categoryId: "thriller",
      title: "Killing Floor: a Jack Reacher novel",
      author: "Lee Child",
    },
    {
      id: "darkness",
      categoryId: "thriller",
      title: "A Darkness More than Night: Harry Bosch Series",
      author: "Michael Connelly",
    },
    {
      id: "neuromancer",
      categoryId: "sci-fi-fantasy",
      title: "Neuromancer",
      author: "William Gibson",
    },
    {
      id: "14",
      categoryId: "sci-fi-fantasy",
      title: "14: Threshold Universe series",
      author: "Peter Clines",
    },
    {
      id: "martian",
      categoryId: "literature",
      title: "The Martian",
      author: "Andy Weir",
    },
  ],
};
